import React, { useRef } from 'react'
import Lottie from 'react-lottie';

import email from '../email (2).json'
import '../css/main.css'


const Contact1 = () => {

    const ref1 = useRef('')
    const ref2 = useRef('')
    const ref3 = useRef('')

    const inputClicked = (e) => {
        e.preventDefault()
        

        ref1.current.style.display = "none";


        
        ref2.current.style.display = "block";
        
        ref3.current.style.display = "block";
        
    }
    const arrowClicked = (e) => {
        e.preventDefault()
        
        

        ref1.current.style.display = "block";
        
        ref2.current.style.display = "none";
        
        ref3.current.style.display = "none";
        
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: email,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className='flex mt-[72px] w-[90%] mx-auto px-10 pt-20 '>
            <div className='w-full  delay-300 ease-in-out duration-2000' >
                <div className='font-bold text-[#2b3342] text-5xl ' style={{transition:'visibility 2s linear 0.5s,opacity 0.33s linear'}} ref={ref1}>
                    <p>Let's </p>
                    <p>Create</p>  
                    <p>Experiences!</p>
                    <p className='font-bold text-2xl mt-2 text-[#2b3342]'>Let's talk.</p>
                </div>
                <svg ref={ref2} onClick={arrowClicked} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden w-8 h-8 text-[#2b3342] cursor-pointer transition-all delay-300 ease-in-out duration-2000">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                </svg>

                <form className='my-10 transition-all-all delay-300 ease-in-out duration-2000'>
                    <div className='my-3'>
                        <span className='text-base font-semibold'>Hi! I am </span>
                        <input type='text' onClick={inputClicked} className='border-b focus:border-b-blue-600  border-b-gray-400 outline-none text-blue-600 text-base font-semibold ml-2 ' placeholder='eg. John' style={{}} />
                    </div>
                    <div className='my-3'>
                        <span className='text-base font-semibold'>Reach me at </span>
                        <input type='text' onClick={inputClicked} className='border-b focus:border-b-blue-600  border-b-gray-400 outline-none text-blue-600 text-lg font-semibold ml-2 ' placeholder='eg. hello@gmail.com' style={{}} />
                    </div>
                    <div className='hidden transition-all delay-300 ease-in-out duration-2000' style={{transition:'visibility 2s linear 0.5s,opacity 0.33s linear'}} ref={ref3}>

                        <div className='my-3'>
                            <span className='text-base font-semibold'>Country</span>
                            <input type='text' className='border-b focus:border-b-blue-600  border-b-gray-400 outline-none text-blue-600 text-lg font-semibold ml-2 ' placeholder='eg. India' style={{}} />
                        </div>
                        <div className='my-3'>
                            <span className='text-base font-semibold'>Mobile No.</span>
                            <input type='text' className='border-b focus:border-b-blue-600  border-b-gray-400 outline-none text-blue-600 text-lg font-semibold ml-2 ' placeholder='eg. +91 9123455655' style={{}} />
                        </div>
                        <div className='my-3'>
                            <span className='text-base font-semibold'>Company Name</span>
                            <input type='text' className='border-b focus:border-b-blue-600  border-b-gray-400 outline-none text-blue-600 text-lg font-semibold ml-2 ' placeholder='eg. Vedas IT' style={{}} />
                        </div>

                        <div className='flex gap-4'>
                            <button className='border  border-gray-300 focus:bg-red-500 focus:text-white px-8 rounded-md mt-4 py-1 text-base tracking-wide font-semibold ' onClick={(event) => event.preventDefault()}>UI-UX</button>
                            <button className='border border-gray-300 focus:bg-red-500 focus:text-white px-8 rounded-md mt-4 py-1 text-base tracking-wide font-semibold ' onClick={(event) => event.preventDefault()}>Mobile Apps.</button>
                            <button className='border border-gray-300 focus:bg-red-500 focus:text-white px-8 rounded-md mt-4 py-1 text-base tracking-wide font-semibold ' onClick={(event) => event.preventDefault()}>Web tech</button>
                            {/* <button className='border  px-8 rounded-md mt-4 py-2 text-lg tracking-wide font-medium '>SEO</button> */}
                        </div>
                        <div className='flex gap-4'>
                            <button className='border border-gray-300 focus:bg-red-500 focus:text-white px-8 rounded-md mt-4 py-1 text-base tracking-wide font-semibold ' onClick={(event) => event.preventDefault()}>Branding</button>
                            <button className='border border-gray-300 focus:bg-red-500 focus:text-white px-8 rounded-md mt-4 py-1 text-base tracking-wide font-semibold ' onClick={(event) => event.preventDefault()}>SEO</button>
                            <button className='border border-gray-300 focus:bg-red-500 focus:text-white px-8 rounded-md mt-4 py-1 text-base tracking-wide font-semibold ' onClick={(event) => event.preventDefault()}>Other</button>
                        </div>
                        <div className='my-3'>
                            <p className='text-base font-semibold'>Comments</p>
                            <textarea cols={50} rows={2} className="border-b focus:border-b-blue-600  border-b-gray-400 outline-none text-blue-600 text-lg font-semibold " placeholder="Drop Your Message" ></textarea>
                        </div>
                    </div>
                    <div>
                        <button className='border border-black px-8 rounded-md mt-8 py-2 text-lg tracking-wide font-bold '>Send</button>
                    </div>
                </form>
            </div>
            <div className='-mt-12 text-right w-full'>
                <Lottie
                    options={defaultOptions}
                    height={400}
                    width={400}
                />
                <div className='px-24'>

                    <a href='/' className='text-blue-600 border-b text-lg  border-b-blue-600 hover:border-b-0 my-2'>info@vedasIT.com</a>
                    <p className='text-lg font-semibold text-gray-400'>IND :<span className='text-black ml-2 my-2 font-normal'>+91 9102363666</span></p>
                    <p className='text-lg font-semibold text-gray-400'>USA :<span className='text-black ml-2 my-2 font-normal'>+1 (802) 123-2345</span></p>
                    <p className='text-lg font-semibold text-gray-400'>Join our Team :<span className='text-black my-2 ml-2 font-normal'>+91 9102363666</span></p>
                </div>
            </div>
        </div>
    )
}

export default Contact1
