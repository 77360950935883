import React from 'react'
import Hero from './Hero'
import Clients from './Clients'
import Service from './Services'
import Process1 from './process1'
import Technology from './Technology'
import Quote from './Quote'
import Growth from './Growth'
import Testonomial from './Testonomial'

const Home = () => {
  return (
    <div className='-z-10' >
      <Hero/>
      <Clients/>
      <Process1/>
      <Service/>
      <Quote/>
      <Technology/>
      <Growth/>
      <Testonomial/>
    </div>
  )
}

export default Home
