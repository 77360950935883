import React from 'react'

const Team = () => {
  return (
    <div>
      Team
      Team
      Team
      Team
      Team
      Team
      Team
      Team
      Team
      Team
      Team
    </div>
  )
}

export default Team
