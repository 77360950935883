import React from 'react'
import adani from '../images/adani.png'
import LV from '../images/LV.png'
import reliance from '../images/reliance.jpeg'
import rado from '../images/rado.png'
import tata from '../images/tata.jpeg'
import tesla from '../images/tesla.png'
// import Ticker from "react-ticker";


const Clients = () => {
  return (
    <div className='my-6 ' style={{background: "linear-gradient(135deg, #e6f7fa 0%, #fcfdeb 100%)"}}>
        <div className='py-4 px-10 flex justify-center items-center gap-2' >
        {/* <Ticker> */}
            <img src='logo.PNG' alt='' className='w-44' style={{aspectRatio:"3/2",objectFit:"contain"}}/>
            {/* <img src={LV} alt=''  className=' w-24' style={{aspectRatio:"3/2",objectFit:"contain",}}/>
            <img src={reliance} alt=''  className=' w-24' style={{aspectRatio:"3/2",objectFit:"contain",}}/>
            <img src={rado} alt='' className=' w-24' style={{aspectRatio:"3/2",objectFit:"contain",}} />
            <img src={tata} alt='' className=' w-24' style={{aspectRatio:"3/2",objectFit:"contain",}}/>
            <img src={tesla} alt='' className=' w-24' style={{aspectRatio:"3/2",objectFit:"contain"}} /> */}
    {/* </Ticker> */}
        </div>
      
    </div>
  )
}

export default Clients
