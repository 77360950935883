import React, {  } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faXTwitter, faWhatsapp,faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import '../css/main.css'
import {faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import flag from '../images/flag.png'

const Footer = () => {
    
    return (

        <>
            <div  className=' mt-12 relative border border-[rgb(28,28,29)] mx-auto text-center my-6  w-4/5  rounded-lg p-10   ' style={{backgroundImage: "linear-gradient(75deg, #9daaf2 7%, #f05d6a 93%)",boxShadow: "5px 5px 3px 0px #0a0b1e"}}>
                <h1 className=' font-semibold tracking-wide text-3xl text-white' style={{ fontFamily: "outfit,sans-serif" , color: "", lineHeight: "150%" }}>"Join Our Community by using our
                    services and grow your business."
                </h1>
                

                <div className='mt-8 mb-4' style={{
                    fontFamily: "outfit,sans-serif",
                }}><Link to="/contact" className='nav_button list-none border px-5 py-3 text-[18px] font-semibold  cursor-pointer border-black  rounded-md bg-white  '>  Contact Us   </Link></div>
 
        
            </div>
            <div className='py-10 px-20 w-full flex-col rounded-t-[100px]' style={{ background: " linear-gradient(60deg, rgb(58, 58, 69) 3%, rgb(23, 22, 68) 34%, rgb(52, 37, 49) 90%)",
                fontFamily: "outfit,sans-serif"}}>
                <div className=' flex justify-around  '>

                    <div className='max-w-[450px] text-left '>
                        <h1 className='text-2xl  font-bold text-[#ee8a60] tracking-wide' style={{fontFamily:"cursive"}}>Vedas IT</h1>
                        <p className='text-[16px] mt-10  font-medium opacity-70 tracking-wide ' style={{ color: "#ffffffbf", fontFamily: "", lineHeight: "200%" }}>Hey Still Confused? No Worries, You Can Directly Chat With Our Sales Representatives, They Will Assist You With Any Technical Related Business Concerns.
                        </p>

                    </div>

                    <div className='text-left' >
                        <h1 className='text-xl  font-medium  text-[#ee8a60] '>Get In Touch</h1>
                        <p className=' mt-10 font-medium flex items-center gap-2' style={{ color: "#ffffffbf" }}><FontAwesomeIcon  icon={faLocationDot} className='w-[20px] h-[20px] aspect-square object-contain'  /> <Link to='/' className='opacity-70'>Surat,Gujarat</Link></p>
                        
                        <p className='mt-4  font-medium flex items-center gap-2' style={{ color: "#dce2f3" }}><FontAwesomeIcon  icon={faEnvelope} className='w-[20px] h-[20px] aspect-square object-contain'  /> <Link to='/' className='opacity-70'>hr@gmail.com</Link></p>
                        <p className='mt-4  font-medium flex items-center gap-2' style={{ color: "#dce2f3" }}><img src={flag} className='w-[20px] h-[20px] aspect-square object-contain'  alt='indian'/> <span className='opacity-70'>+91 9106795695</span></p>

                    </div>
                    <div className='text-left'>
                        <h1 className='text-xl text-[#ee8a60] font-medium '>Help & Advise</h1>
                        <ul className='mt-10  font-medium opacity-70 ' style={{ color: "#ffffffbf", fontFamily: "" }}>
                            <li className='mb-2   hover:text-white'><Link to='' className='relative provience_trans '>About Us</Link></li>
                            <li className='mb-2  hover:text-white'><Link to='' className='relative provience_trans'>Career</Link></li>
                            <li className='mb-2  hover:text-white'><Link to='' className='relative provience_trans'>Blog</Link></li>
                            <li className='mb-2  hover:text-white '><Link to='' className='relative provience_trans'>Contact Us</Link></li>
                            <li className='mb-6  hover:text-white'><Link to='' className='relative provience_trans'>Privacy & policy</Link></li>
                        </ul>

                    </div>
                </div>
                <div className='flex mt-10 px-10 py-4 items-center justify-between  rounded-full text-black bg-white' style={{fontFamily: "outfit,sans-serif" }}>
                    <p className='  text-[14px] font-semibold opacity-70' style={{ color: "#1c1c1d" }}>
                        &#169;2023 All rights reserved by Vedas IT.</p>

                    <div className=' flex items-center gap-4 cursor-pointer' style={{ color: "" }}>
                        <span className='text-[#ee8a60] font-semibold'>Reach out</span>
                        <FontAwesomeIcon icon={faLinkedinIn}  className='w-4 h-4 rounded-full border p-2 border-black hover:-translate-y-2 transition ease-in-out delay-200 duration-300' />
                        <FontAwesomeIcon icon={faXTwitter} className='w-4 h-4 rounded-full border p-2 border-black hover:-translate-y-2 transition ease-in-out delay-200 duration-300' />
                        <FontAwesomeIcon icon={faInstagram} className='w-4 h-4 rounded-full border p-2 border-black hover:-translate-y-2 transition ease-in-out delay-200 duration-300' />
                        <FontAwesomeIcon icon={faWhatsapp} className='w-4 h-4 rounded-full border p-2 border-black hover:-translate-y-2 transition ease-in-out delay-200 duration-300' />
                        
                    </div>

                </div>
            </div></>

    )
}

export default Footer 
