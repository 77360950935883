import './App.css';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar';
import Contact1 from './components/contact1';
import Career from './components/Career';
import {
  Routes,
  Route,
} from "react-router-dom";
import Team from './components/Team';
import Design from './components/Design';
import Fullstack from './components/FullStack';
import MERN from './components/MERN';
import API from './components/API';
import Flutter from './components/Flutter';
import Ios from './components/Ios';
import Android from './components/Android';
import ReactNative from './components/ReactNative';

import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div className="App">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home/>}></Route>
          <Route exact path="/about" >
            <Route exact path="aboutUs" element={<About/>}></Route>
            <Route exact path="team" element={<Team/>}></Route>
            <Route exact path="career" element={<Career/>}></Route>
          </Route>
          <Route exact path="/services" >
            <Route exact path="design" element={<Design/>}></Route>
            <Route exact path="fullstack" element={<Fullstack/>}></Route>
            <Route exact path="mern" element={<MERN/>}></Route>
            <Route exact path="api" element={<API/>}></Route>
            <Route exact path="flutter" element={<Flutter/>}></Route>
            <Route exact path="ios" element={<Ios/>}></Route>
            <Route exact path="android" element={<Android/>}></Route>
            <Route exact path="native" element={<ReactNative/>}></Route>
          </Route>

          <Route exact path="/contact" element={<Contact1/>}></Route>
        </Routes>
      <Footer />
      
    </div>
  );
}

export default App;
