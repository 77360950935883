import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import '../css/main.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'


const Navbar = () => {

    const location = useLocation()
    // console.log(pathname)

    const [handleScroll, sethandleScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                sethandleScroll(true)
            }
            else {
                sethandleScroll(false)
            }
        })
    }, [])


    return (
        <div className={`z-10   fixed top-0 left-0 w-full   ${handleScroll === true ? 'bg-white shadow-md':` ${location.pathname.startsWith('/services') ? 'bg-white':'bg-white'}`}`} >
            <div className={` relative flex items-center  justify-around ${handleScroll === true ? ' navTrans h-[80px]' : 'h-[80px]'} }`}>
                <Link to='/' className='text-3xl  align-middle font-semibold  logo'>Vedas IT</Link>
                <ul className=' list-none flex  items-center gap-6 text-[15px] font-medium  cursor-pointer    text-[#0000008C] tracking-wide ' style={{ lineHeight: "1", fontFamily: "outfit,sans-serif" }}>
                    <li className={`${location.pathname === '/' ? 'text-[#F05D6A]' : 'hover:text-black/70 '}`}><Link to='' href="/">Home</Link></li>

                    <li className={` ${location.pathname === '' ? 'text-[#F05D6A]' : 'hover:text-black/70 '}`}><Link to='' href="">Work</Link></li>
                    <li className={` ${location.pathname.startsWith('/about')  ? 'text-[#F05D6A]' : 'hover:text-black/70 '} group/parent   `}><span>About</span>
                        <FontAwesomeIcon className='ml-2  group-hover/parent:rotate-180 transition-all ease-in-out duration-300 ' icon={faAngleDown} />

                        <div className="z-20  shadow-xl   group-hover/parent:block pt-6    left-0 absolute    hidden h-auto w-screen">
                            <ul className=" border-t flex  items-center justify-around w-screen  rounded-md pb-3 pt-3 px-10 bg-white">
                                <li className="group/child bg-[#FFD1E3]/90 text-[13px] text-[#212529] font-normal rounded-xl w-[380px] px-4 pr-8 py-4">
                                    <Link to='/about/aboutUs'>

                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">About us</p>
                                        <p className=''>We are super efficient </p>
                                        <p className=' flex items-center justify-between'>
                                            <span>yet humble to serve you!</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 group-hover/child:translate-x-3 transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </p>
                                    </Link>
                                </li>
                                <li className="group/child bg-[#C499F3]/50 text-[13px] text-[#212529] font-normal rounded-xl w-[380px] px-4 pr-8 py-4">
                                    <Link to='/about/team'>
                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">Team</p>
                                        <p className=''>We are proud of our experienced </p>
                                        <p className=' flex items-center justify-between'>
                                            <span>and accomplished team!</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 group-hover/child:translate-x-3 transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </p>
                                    </Link>
                                </li>
                                <li className="group/child bg-[#BFCFE7]/50 text-[13px] text-[#212529] font-normal rounded-xl w-[380px] px-4 pr-8 py-4">
                                    <Link to='/about/career'>
                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">Career</p>
                                        <p className=''>Can you offer </p>
                                        <p className=' flex items-center justify-between'>
                                            <span>such experienced!</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 group-hover/child:translate-x-3 transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </p>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li className={` ${location.pathname === '' ? 'text-[#F05D6A]' : 'hover:text-black/70 '}`}><Link to='' href="">Clients</Link></li>
                    <li className={` ${location.pathname.startsWith('/services') ? 'text-[#F05D6A]' : 'hover:text-black/70 '} group/parent   `}><span>Services</span>
                        <FontAwesomeIcon className='ml-2  group-hover/parent:rotate-180 transition-all ease-in-out duration-300 ' icon={faAngleDown} />
                        <div className="z-20  shadow-xl   group-hover/parent:block pt-6    left-0 absolute    hidden h-auto w-screen">
                            <ul className="  flex gap-3 items-center justify-around w-screen border-t rounded-md pt-3 pb-3 px-10 bg-white flex-wrap">
                                <li className="group/child bg-[#FFD1E3]/50 text-[13px] text-[#212529] font-normal rounded-xl h-[150px] w-[380px] px-4 pr-8 py-2">
                                <p>
                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">Design</p>
                                        <Link to='/services/design' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span> Web Designing</Link>
                                        </p>
                                </li>
                                <li className="group/child bg-[#C499F3]/30 text-[13px] text-[#212529] font-normal rounded-xl h-[150px] w-[380px] px-4 pr-8 py-2">
                                    <p>
                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">Website</p>
                                        <Link to='/services/fullstack' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  Fullstack Development</Link>
                                        <Link to='/services/mern' className=' block hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  MERN Development</Link>
                                        <Link to='/services/api' className=' block hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  API Development</Link>

                                    </p>
                                </li>
                                <li className="group/child bg-[#C3E2C2]/30 text-[13px] text-[#212529] font-normal rounded-xl h-[150px] w-[380px] px-4 pr-8 py-2">
                                    <p>
                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">MOBILE APPS</p>
                                        <Link to='/services/flutter' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  Flutter</Link>
                                        <Link to='/services/ios' className=' block hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  IOS</Link>
                                        <Link to='/services/android' className=' block hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  Andriod</Link>
                                        <Link to='/services/native' className=' block hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  React Native</Link>

                                    </p>
                                </li>
                                <li className="group/child bg-[#B4D4FF]/50 text-[13px] text-[#212529] font-normal rounded-xl h-[150px] w-[380px] px-4 pr-8 py-2">
                                    <p>
                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">E-COMMERCE</p>
                                        <Link to='' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  Shopify Development</Link>
                                    </p>
                                </li>

                                <li className="group/child bg-[#FFB996]/50 text-[13px] text-[#212529] font-normal rounded-xl h-[150px] w-[380px] px-4 pr-8 py-2">
                                    <p>
                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">CMS</p>
                                        <Link to='' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  WordPress Development</Link>


                                    </p>
                                </li>
                                <li className="group/child bg-[#E5E1DA]/50 text-[13px] text-[#212529] font-normal rounded-xl h-[150px] w-[380px] px-4 pr-8 py-2">
                                    <p>

                                        <p className="block font-semibold text-lg text-[#212529]  mb-2 cursor-pointer">Marketing</p>
                                        <Link to='' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  Social Media Marketing</Link>
                                        <Link to='' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  SEO Marketing</Link>
                                        <Link to='' className=' block  hover:translate-x-2 transition delay-150 ease-in-out duration-500'><span className='font-semibold text-base'>&gt;</span>  Content Marketing</Link>


                                    </p>
                                </li>

                            </ul>
                        </div>
                    </li>
                    {/* <li className={` ${location.pathname === '' ? 'text-[#F05D6A]' : 'hover:text-black/70 '} group  relative dropdown`}>Portfolio
                        <FontAwesomeIcon className='ml-2' icon={faCaretDown} />
                        <div className="group-hover:block dropdown-menu   text-left absolute hidden h-auto">
                            <ul className="top-0  bg-white w-40 shadow rounded-md  py-2">
                                <li className=""><Link to='' className="block px-4 py-2 font-medium text-[#212529] hover:text-[#F05D6A] hover:bg-[#F05D6A]/10 cursor-pointer">IOS Apps</Link></li>
                                <li className=""><Link to='' className="block px-4 py-2 text-[#212529]  hover:bg-[#F05D6A]/10 font-medium  hover:text-[#F05D6A] cursor-pointer">Andriod Apps</Link></li>
                                <li className=""><Link to='' className="block px-4 py-2 text-[#212529]  hover:bg-[#F05D6A]/10 font-medium  hover:text-[#F05D6A] cursor-pointer">Andriod Apps</Link></li>
                                <li className=""><Link to='' className="block px-4 py-2 text-[#212529]  hover:bg-[#F05D6A]/10 font-medium  hover:text-[#F05D6A] cursor-pointer">UI/UX Design</Link></li>
                                <li className=""><Link to='' className="block px-4 py-2 text-[#212529]  hover:bg-[#F05D6A]/10 font-medium  hover:text-[#F05D6A] cursor-pointer">Web Design</Link></li>
                                <li className=""><Link to='' className="block px-4 py-2 text-[#212529]  hover:bg-[#F05D6A]/10 font-medium  hover:text-[#F05D6A] cursor-pointer">E-commorce</Link></li>
                                <li className=""><Link to='' className="block px-4 py-2 text-[#212529]  hover:bg-[#F05D6A]/10 font-medium  hover:text-[#F05D6A] cursor-pointer">Hybrid/Cross Platform</Link></li>
                                <li className=""><Link to='' className="block px-4 py-2 text-[#212529]  hover:bg-[#F05D6A]/10 font-medium  hover:text-[#F05D6A] cursor-pointer">All</Link></li>
                            </ul>
                        </div>
                    </li> */}
                    <li className={` ${location.pathname === '' ? 'text-[#F05D6A]' : 'hover:text-black/70 '} group  relative dropdown`}>Knowledge

                    </li>


                </ul>
                <Link to='/contact'>

                    <li className='z-10 bg-white nav_button list-none border px-[16px] py-[10px] text-[14px] font-semibold  cursor-pointer border-black  rounded-md' style={{
                        lineHeight: "1", fontFamily: "outfit,sans-serif",

                    }}>  Contact Us</li>
                </Link>

            </div>


        </div>
    )
}

export default Navbar
