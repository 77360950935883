
import React, { useEffect, useRef } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import android from '../images/android.png'
import atom from '../images/atom.png'
import apple from '../images/apple.png'
import flutter from '../images/flutter (1).png'
import Angular from '../images/Angular.png'
import Codelgniter from '../images/Codelgniter.png'
import Figma from '../images/Figma.png'
import HTML5 from '../images/HTML5.png'
import Illustrator from '../images/Illustrator.png'
import JavaScript from '../images/JavaScript.png'
import Laravel from '../images/Laravel.png'
import MongoDB from '../images/MongoDB.png'
import MySQL from '../images/MySQL.png'
import NodeJS from '../images/NodeJS.png'
import ORACLE from '../images/ORACLE.png'
import Photoshop from '../images/Photoshop.png'
import PHP from '../images/PHP.png'
import PostgreSQL from '../images/PostgreSQL.png'
import ReactJS from '../images/ReactJS.png'
import VueJS from '../images/VueJS.png'


const Technology = () => {
    const list1 = useRef()
    const list2 = useRef()
    const list3 = useRef()
    const list4 = useRef()
    const list5 = useRef()

    const li1 = useRef()
    const li2 = useRef()
    const li3 = useRef()
    const li4 = useRef()
    const li5 = useRef()

    useEffect(() => {
        AOS.init();
    }, [])


    const handlelist1 = (event) => {
        li2.current.style.color = "#51596c";
        li2.current.style.borderBottom = "0px solid orange";
        li1.current.style.color = "#0093ed";
        li1.current.style.borderBottom = "2px solid #0093ed";
        li3.current.style.color = "#51596c";
        li3.current.style.borderBottom = "0px solid orange";
        li4.current.style.color = "#51596c";
        li4.current.style.borderBottom = "0px solid orange";
        li5.current.style.color = "#51596c";
        li5.current.style.borderBottom = "0px solid orange";
        list2.current.style.display = "none"
        list1.current.style.display = "block"
        list3.current.style.display = "none"
        list4.current.style.display = "none"
        list5.current.style.display = "none"
    }
    const handlelist2 = (event) => {
        event.preventDefault()

        li1.current.style.color = "#51596c";
        li1.current.style.borderBottom = "0px solid orange";
        li2.current.style.color = "#0093ed";
        li2.current.style.borderBottom = "2px solid #0093ed";
        li3.current.style.color = "#51596c";
        li3.current.style.borderBottom = "0px solid orange";
        li4.current.style.color = "#51596c";
        li4.current.style.borderBottom = "0px solid orange";
        li5.current.style.color = "#51596c";
        li5.current.style.borderBottom = "0px solid orange";

        list2.current.style.display = "block"
        list1.current.style.display = "none"
        list3.current.style.display = "none"
        list4.current.style.display = "none"
        list5.current.style.display = "none"

    }
    const handlelist3 = (event) => {
        event.preventDefault()
        li1.current.style.color = "#51596c";
        li1.current.style.borderBottom = "0px solid orange";
        li3.current.style.color = "#0093ed";
        li3.current.style.borderBottom = "2px solid #0093ed";
        li2.current.style.color = "#51596c";
        li2.current.style.borderBottom = "0px solid orange";
        li4.current.style.color = "#51596c";
        li4.current.style.borderBottom = "0px solid orange";
        li5.current.style.color = "#51596c";
        li5.current.style.borderBottom = "0px solid orange";
        list3.current.style.display = "block"
        list1.current.style.display = "none"
        list2.current.style.display = "none"
        list4.current.style.display = "none"
        list5.current.style.display = "none"
    }
    const handlelist4 = (event) => {
        event.preventDefault()
        li1.current.style.color = "#51596c";
        li1.current.style.borderBottom = "0px solid orange";
        li4.current.style.color = "#0093ed";
        li4.current.style.borderBottom = "2px solid #0093ed";
        li3.current.style.color = "#51596c";
        li3.current.style.borderBottom = "0px solid orange";
        li2.current.style.color = "#51596c";
        li2.current.style.borderBottom = "0px solid orange";
        li5.current.style.color = "#51596c";
        li5.current.style.borderBottom = "0px solid orange";
        list4.current.style.display = "block"
        list1.current.style.display = "none"
        list2.current.style.display = "none"
        list3.current.style.display = "none"
        list5.current.style.display = "none"
    }
    const handlelist5 = (event) => {
        event.preventDefault()
        li1.current.style.color = "#51596c";
        li1.current.style.borderBottom = "0px solid orange";
        li5.current.style.color = "#0093ed";
        li5.current.style.borderBottom = "2px solid #0093ed";
        li3.current.style.color = "#51596c";
        li3.current.style.borderBottom = "0px solid orange";
        li4.current.style.color = "#51596c";
        li4.current.style.borderBottom = "0px solid orange";
        li2.current.style.color = "#51596c";
        li2.current.style.borderBottom = "0px solid orange";
        list5.current.style.display = "block"
        list1.current.style.display = "none"
        list2.current.style.display = "none"
        list3.current.style.display = "none"
        list4.current.style.display = "none"
    }


    return (
        <div className='-mt-28 '>
            <div className='  w-full  ' id='1' >
                
                <svg className=' ' width="100%" height="100%" id="svg" viewBox="0 0 1440 590" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#e6f7fa"></stop><stop offset="95%" stop-color="#fcfdeb"></stop></linearGradient></defs><path d="M 0,600 L 0,225 C 202.53333333333336,206.73333333333335 405.0666666666667,188.46666666666667 547,172 C 688.9333333333333,155.53333333333333 770.2666666666667,140.86666666666667 909,150 C 1047.7333333333333,159.13333333333333 1243.8666666666668,192.06666666666666 1440,225 L 1440,600 L 0,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 300)"></path></svg>
                <div className='w-4/5 mx-auto -mt-[600px]'>

                    <h2 className='mt-16   py-2  text-center font-semibold  text-3xl' style={{fontFamily:"josefin sans", color: "#2b3342" }}>Technologies We Work With</h2>
                    <div className='text-center text-base ' style={{ color: "#51596c" }}>Our expert team of developers transforms your ideas into incredible apps and software by leveraging the latest technologies.</div>
                    <ul className='flex text-xl   border-b  text-[#8ca1b9] font-normal   z-10 pt-10  justify-center gap-12 ' style={{fontFamily:"josefin sans"}}>
                        <li className='border-b-2  py-2  ' style={{ color: "#0093ed", borderColor: "#0093ed" }} ref={li1} onClick={handlelist1}><Link href='#1'>Mobile</Link></li>
                        <li className="py-2 " onClick={handlelist2} ref={li2}><Link href='#1' >Front End</Link></li>
                        <li className="py-2 " onClick={handlelist3} ref={li3}><Link href='#1'> Database</Link></li>
                        <li className="py-2 " onClick={handlelist4} ref={li4}><Link href='#1'>Back End</Link></li>
                        <li className="py-2 " onClick={handlelist5} ref={li5}><Link href='#1'>Designing</Link></li>
                    </ul>
                    <div className='relative mb-20 mt-10 cursor-pointer' data-aos="fade-left" data-aos-duration="2000">
                        <div className='relative transition ease-in-out delay-150 duration-300' ref={list1} >
                            <ul className='flex justify-center gap-10   mb-10 '>
                                <li className='bg-white  hover:shadow-xl hover:text-[#0093ed] rounded-2xl   h-36 w-36   '>
                                    <img src={flutter} className='mx-auto mt-6 box-content object-contain overflow-hidden' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4   opacity-90'>Flutter</p>
                                </li>
                                <li className='bg-white rounded-2xl  hover:shadow-xl hover:text-[#0093ed] h-36 w-36 '>
                                    <img src={apple} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Apple</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed]   h-36 w-36  '>
                                    <img src={android} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Android</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed]  h-36 w-36   '>
                                    <img src={atom} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>React Native</p>
                                </li>
                            </ul>
                        </div>
                        <div className='hidden mx-auto transition ease-in-out delay-150 duration-300' ref={list2} >
                            <ul className='flex justify-center gap-10   mb-10 ' >
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed]  h-36 w-36   '>
                                    <img src={ReactJS} className='mx-auto mt-6 box-content object-contain overflow-hidden' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4   opacity-90'>ReactJS</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed]  h-36 w-36 '>
                                    <img src={VueJS} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>VueJS</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed] h-36 w-36  '>
                                    <img src={Angular} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Angular</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed]  h-36 w-36   '>
                                    <img src={JavaScript} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Java Script</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed] h-36 w-36   '>
                                    <img src={HTML5} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>HTML</p>
                                </li>
                            </ul>
                        </div>
                        <div className='hidden mx-auto transition ease-in-out delay-150 duration-300' ref={list3} >
                            <ul className='flex justify-center gap-10   mb-10 '>
                                <li className='bg-white hover:shadow-xl hover:text-[#0093ed] rounded-2xl   h-36 w-36   '>
                                    <img src={MongoDB} className='mx-auto mt-6 box-content object-contain overflow-hidden' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4   opacity-90'>MongoDB</p>
                                </li>
                                <li className='bg-white hover:shadow-xl hover:text-[#0093ed] rounded-2xl  h-36 w-36 '>
                                    <img src={ORACLE} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Oracle</p>
                                </li>
                                <li className='bg-white hover:shadow-xl hover:text-[#0093ed] rounded-2xl  h-36 w-36  '>
                                    <img src={MySQL} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>MySQL</p>
                                </li>
                                <li className='bg-white hover:shadow-xl hover:text-[#0093ed] rounded-2xl  h-36 w-36   '>
                                    <img src={PostgreSQL} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>PostgreSQL</p>
                                </li>
                            </ul>
                        </div>
                        <div className='hidden mx-auto transition ease-in-out delay-150 duration-300' ref={list4} >
                            <ul className='flex justify-center gap-10   mb-10 '>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed]  h-36 w-36   '>
                                    <img src={NodeJS} className='mx-auto mt-6 box-content object-contain overflow-hidden' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4   opacity-90'>NodeJS</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed] h-36 w-36 '>
                                    <img src={PHP} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>PHP</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed]  h-36 w-36  '>
                                    <img src={Laravel} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Laravel</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed] h-36 w-36   '>
                                    <img src={Codelgniter} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Codelgniter</p>
                                </li>
                            </ul>
                        </div>
                        <div className='hidden mx-auto transition ease-in-out delay-150 duration-300' ref={list5} >
                            <ul className='flex justify-center gap-10   mb-10 '>
                                <li className='bg-white rounded-2xl  hover:shadow-xl hover:text-[#0093ed] h-36 w-36   '>
                                    <img src={Figma} className='mx-auto mt-6 box-content object-contain overflow-hidden' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4   opacity-90'>Figma</p>
                                </li>
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed] h-36 w-36 '>
                                    <img src={Illustrator} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Illustrator</p>
                                </li>
                                {/* <li className='bg-white rounded-2xl  h-36 w-36  '>
                                    <img src='/XD.png' className='mx-auto mt-6' height={50} width={61} alt='' />
                                    <p className='text-center mt-4  opacity-90'>XD</p>
                                </li> */}
                                <li className='bg-white rounded-2xl hover:shadow-xl hover:text-[#0093ed] h-36 w-36   '>
                                    <img src={Photoshop} className='mx-auto mt-6' width={80} style={{ aspectRatio: "3/2", objectFit: "contain" }} alt='' />
                                    <p className='text-center mt-4  opacity-90'>Photoshop</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Technology
