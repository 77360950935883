import React from 'react'
import { Link } from 'react-router-dom'
import { useTypewriter } from 'react-simple-typewriter'

import '../css/main.css'

const Hero = () => {
    const [typeEffect]=useTypewriter({
        words:['Development','Design'],
        loop:{},
        typeSpeed:100,
        deleteSpeed:40
    })
  return (
    <div className='h-screen mt-[71px]  flex items-center  mx-auto ' style={{overflowX:"hidden"}} >
        <div className='flex-1 ml-28'>
          <h1 className=' font-[500] tracking-wide text-[#2b3342] ' style={{fontFamily:"josefin sans",
            fontSize: "50px", color: "1c1c1d", wordSpacing: "0.1rem", lineHeight: "normal"
          }}>Elevate Your Business With Innovative IT Solutions. </h1>
          <div className='flex items-center mt-6 '>
            <h2 className='flex items-center text-2xl   font-medium' style={{ color: "#6d788c" }}>We Build Digital Business, Web & Apps
            <span className='ml-4 text-[30px] main font-semibold'>{typeEffect}</span>
           
</h2>
            <span className="italic text-3xl font-bold ml-3 text-[#e7b900] " style={{ color: "" }}>
            </span>
          </div>
          <button className='z-10 mt-10 bg-white nav_button list-none border px-[18px] py-[12px] text-[16px] font-bold  cursor-pointer border-black  rounded-md '>
            <Link to="/contact"  >Get In Touch</Link>
          </button>
        </div>
        <div className=' '>


        <img className="h-screen " src='banner.svg'  alt="developer" />
        </div>
      </div>
    
  )
}

export default Hero
